(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/param-handler/assets/javascripts/param-handler.js') >= 0) return;  svs.modules.push('/components/utils/param-handler/assets/javascripts/param-handler.js');
"use strict";


const matchRegExpString = '=?[^&]*';
const paramHandler = {
  set(_ref) {
    let {
      key,
      value
    } = _ref;
    let state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    if (typeof state !== 'object') {
      throw new TypeError('state must be of type Object');
    }
    let url = window.location.href;
    const regExp = new RegExp("[^=]\\b".concat(key, "\\b").concat(matchRegExpString), 'g');
    let insert = "".concat(key).concat(value ? "=".concat(value) : '');
    let prefix;
    if (url.match(regExp)) {
      const match = url.match(regExp)[0];
      if (match.indexOf('?') === 0) {
        insert = "?".concat(insert);
      } else if (match.indexOf('&') === 0) {
        insert = "&".concat(insert);
      }
      url = url.replace(regExp, insert);
    } else {
      if (url.match(/\?$/)) {
        prefix = '';
      } else if (url.match(/\?/)) {
        prefix = '&';
      } else {
        prefix = '?';
      }
      url = "".concat(url).concat(prefix).concat(insert);
    }
    history.replaceState(state, null, url);
  },
  get(_ref2) {
    let {
      key
    } = _ref2;
    const url = window.location.href;
    const regExp = new RegExp("\\b".concat(key, "\\b").concat(matchRegExpString), 'g');
    let value = url.match(regExp);
    if (value && value.length) {
      const split = value[0].split('=');
      value = split.length > 1 ? value[0].split('=')[1] : true;
    }
    return {
      key,
      value
    };
  },
  remove(_ref3) {
    let {
      key
    } = _ref3;
    let state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    if (typeof state !== 'object') {
      throw new TypeError('state must be of type Object');
    }
    let url = window.location.href;
    const regExp = new RegExp("\\b".concat(key, "\\b").concat(matchRegExpString), 'g');
    if (url.match(regExp)) {
      const embeddedParamRegExp = new RegExp("&\\b".concat(key, "\\b").concat(matchRegExpString), 'g');
      const firstParamRegExp = new RegExp("\\b".concat(key, "\\b").concat(matchRegExpString, "&"), 'g');
      if (url.match(embeddedParamRegExp)) {
        url = url.replace(embeddedParamRegExp, '');
      } else if (url.match(firstParamRegExp)) {
        url = url.replace(firstParamRegExp, '');
      } else {
        url = url.replace(regExp, '');
      }
      if (url.match(/\?$/g)) {
        url = url.replace(/\?$/g, '');
      }
      history.replaceState(state, null, url);
    }
  }
};
setGlobal('svs.utils.paramHandler', paramHandler);

 })(window);